import * as React from 'react'
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import ExhibitionsDetail from '../../components/exhibitions/ExhibitionsDetail'
import { Works } from '../../interface'
import SEO from '../../components/seo'


type Props = {
  items: Works[]
  pathname: string,
  data: any
}


class ExhibitionsDetailPage extends React.Component<Props> {

  data:any;

  constructor(props: Props) {
    super(props);	
  }


  render() {

    return <Layout>
      <SEO title={this.props.data.wordpressWpExhibitions.title} />
      {this.props.data.wordpressWpExhibitions && <ExhibitionsDetail data={this.props.data.wordpressWpExhibitions} />}
    </Layout>
  
  }
}

export default ExhibitionsDetailPage



export const query = graphql`
      query($slug: String!) { 
        __typename
        wordpressWpExhibitions(slug: { eq: $slug }) {
          content
          featured_image_src
          title
          acf {
            subtitle
            works_images {
              works_images_image {
                url
              }
              works_images_image_caption
              works_images_image_number_of_columns
            }
          }
        }        
      }

    `;
